/* customScrollbar.css */

/* Style for the entire scrollbar */
::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
    height: 10px; /* Height of the scrollbar (horizontal) */
  }
  
  /* Style for the scrollbar track */
  ::-webkit-scrollbar-track {
    background: #d3d3d3; /* Color of the scrollbar track */
    border-radius: 0px; /* Rounded corners */
  }
  
  /* Style for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #8b8b8b; /* Color of the scrollbar thumb */
    border-radius: 0px; /* Rounded corners */
  }
  
  /* Style for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5f5f5f; /* Color of the scrollbar thumb on hover */
  }
  
